import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand, PutObjectAclCommand, PutObjectAclCommandOutput } from "@aws-sdk/client-s3";

export default class S3API {
  static isS3Url(url): boolean {
    return url && url.match(/\.amazonaws\.com/)
  }

  static async getPresignedUrl(url, expiresIn = 518400): Promise<string> { // 6 day default expiration
    if (!url) {
      return null
    }

    if (!this.isS3Url(url)) {
      // if not an s3 url, just return
      return url
    }

    const client = new S3Client({
      credentials: {
        accessKeyId: process.env.GAWDS_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.GAWDS_AWS_SECRET_ACCESS_KEY
      },
      region: process.env.GAWDS_AWS_REGION
    });

    if (!url) {
      console.warn(`Warning, could not fetch S3 URL for ${url}`)
      return null
    }

    let key = url
    if (key.match(/amazonaws.com/)) {
      key = key.split('amazonaws.com/')[1]
    }

    const command = new GetObjectCommand({
      Bucket: process.env.GAWDS_AWS_BUCKET,
      Key: key
    });

    return await getSignedUrl(client, command, { expiresIn: expiresIn });
  }

  static async fetch(url) {
    if (this.isS3Url(url)) {
      const signed_aws_url = await this.getPresignedUrl(url)
      return await fetch(signed_aws_url)
    }
    else {
      return await fetch(url)
    }
  }

  static async updateAcl(url: string): Promise<PutObjectAclCommandOutput> {
    if (!this.isS3Url(url)) {
      return null
    }

    let key = url
    if (key.match(/amazonaws.com/)) {
      key = key.split('amazonaws.com/')[1]
    }

    const client = new S3Client({
      credentials: {
        accessKeyId: process.env.GAWDS_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.GAWDS_AWS_SECRET_ACCESS_KEY
      },
      region: process.env.GAWDS_AWS_REGION
    });

    return await client.send(
      new PutObjectAclCommand({
        Bucket: process.env.GAWDS_AWS_BUCKET,
        Key: key,
        ACL: 'public-read'
      })
    )
  }
}
