import 'web/lib/string-utils'
import getConfig from 'next/config'
import Gawd from 'web/lib/gawd'
import S3API from 'web/lib/s3-api'
import { getContractData } from 'web/lib/contract'

export default class GawdManifest {
  static PREVIEW_MANIFEST_URL = process.env.PREVIEW_MANIFEST_URL
  static MANIFEST_URL = process.env.MANIFEST_URL

  // Loads in only the Gawds that have been minted (and doesnt load all the metadata)
  /*
   * @return {Gawd[]}
   */
  static async getMintedGawds(ids: number[] | null = null): Promise<Gawd[]> {
    const { publicRuntimeConfig } = getConfig()
    const contractData = await getContractData()

    const json = await this.load(contractData.isLive)
    const totalSupply = contractData.isLive ? contractData.totalSupply : publicRuntimeConfig.previewGawdsCount

    // trim the full manifest just to the currently-minted gawds
    const entries = json.filter((item, i) => {
      if ((ids == null || ids.includes(item.id)) && item.id <= totalSupply) {
        return true
      }
      return false
    }).map((item, i) => {
      return new Gawd(item)
    })

    return entries
  }

  // Same as getMintedGawds, but also loads in all the Gawds metadata
  /*
   * @return {Gawd}
   */
  static async forceLoadMintedGawds(startIndex = null, endIndex = null) {
    let gawds = await this.getMintedGawds()

    // explicitly check for null since startIndex=0 is "false" in javascript
    if (startIndex !== null && endIndex !== null) {
      gawds = gawds.reverse().slice(startIndex, endIndex)
    }

    let gawdsLoader = Promise.all(
      gawds.map(async (gawd) => {
        return await gawd.load()
      })
    )

    return (await gawdsLoader).filter((e) => e != null)
  }

  static async findById(id: number): Promise<Gawd> {
    const mintedGawds = await this.getMintedGawds()
    return mintedGawds.find((gawd) => gawd.id().toString() == id.toString())
  }

  // Load the entire 10K manifest
  /*
   * @return {object[]}
   */
  static async load(isLive:boolean = true) {
    let json = await this.fetchRemoteManifest(isLive)

    if (!json || !json.gawds || json.gawds.length == 0) {
      throw new Error('GawdManifest.load: manifest json is empty, aborting')
    }

    return json.gawds
  }

  static async fetchRemoteManifest(isLive) {
    const manifest = isLive ? this.MANIFEST_URL : this.PREVIEW_MANIFEST_URL

    if (!manifest) {
      throw new Error('MANIFEST_URL env var is missing and no local manifest.json file')
    }

    console.debug('fetchManifest: downloading from remote URL =>', manifest)
    const response = await S3API.fetch(manifest)
    const json = await response.json()

    if (json.errors || !json?.gawds || json?.gawds.length == 0) {
      throw new Error(`getManifest: error parsing json, or file is empty => ${json.errors} ${json}`)
    }

    return json
  }
}
