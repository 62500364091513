import 'web/lib/string-utils'
import S3API from './s3-api'
import GawdManifest from './gawd-manifest'
import { CultType } from './store'
import { captureException } from '@sentry/nextjs'

export enum PowerType {
  Mundane = 'Mundane',
  Toxic = 'Toxic',
  Corporeal = 'Corporeal',
  Verdure = 'Verdure',
  Aqueous = 'Aqueous',
  Creature = 'Creature',
  Chaos = 'Chaos',
  Oblivion = 'Oblivion',
  Dark = 'Dark',
  Mystic = 'Mystic',
  Divine = 'Divine',
  Spirit = 'Spirit',
  Geological = 'Geological',
  Inferno = 'Inferno',
  Automaton = 'Automaton',
  Numerary = 'Numerary',
  Cosmos = 'Cosmos',
  Alchemy = 'Alchemy',
}

export class Cult {
  public id: number
  public name: string
  public fullName: string
  public description: string

  static all: Cult[] = [
    new Cult({
      id: 1,
      name: 'Arcane',
      fullName: 'Arcane Sect',
      description: "Dark, mysterious and full of Blood, the Arcane cult's birth was violent and ecstatic. An offshoot of the original Gawd summoning cult."
    }),
    new Cult({
      id: 2,
      name: 'Astral',
      fullName: 'Astral Cult',
      description: "If you stare into the black of night you will see the Cultists, tapped into the stars, the air, and the very fabric of spacetime itself."
    }),
    new Cult({
      id: 3,
      name: 'Terrene',
      fullName: 'Terrene Division',
      description: "Everything between the heavens and the earth belongs to the Division. All matter and all life are mere instruments of their divine composition."
    }),
  ]

  constructor(data: any) {
    this.id = data?.id
    this.name = data?.name.toString()
    this.fullName = data?.fullName
    this.description = data?.description
  }

  static get(type: CultType): Cult {
    return this.all.find(c => c.name == type.toString())
  }

  static getById(id: number): Cult {
    return this.all.find(c => c.id == id)
  }
}

export class Power {
  public id: number
  public cult: Cult
  public color: string
  public name: PowerType

  static all: Power[] = [
    new Power({ id: 1, name: PowerType.Cosmos, cult: CultType.Astral, color: '#00FFDC' }),
    new Power({ id: 2, name: PowerType.Divine, cult: CultType.Arcane, color: '#FCF6B1' }),
    new Power({ id: 3, name: PowerType.Chaos, cult: CultType.Arcane, color: '#FF0078' }),
    new Power({ id: 4, name: PowerType.Corporeal, cult: CultType.Terrene, color: '#CE034C' }),
    new Power({ id: 5, name: PowerType.Mystic, cult: CultType.Arcane, color: '#8600FF' }),
    new Power({ id: 6, name: PowerType.Creature, cult: CultType.Terrene, color: '#FF8800' }),
    new Power({ id: 7, name: PowerType.Verdure, cult: CultType.Terrene, color: '#3CBAAC' }),
    new Power({ id: 8, name: PowerType.Toxic, cult: CultType.Terrene, color: '#86FF00' }),
    new Power({ id: 9, name: PowerType.Dark, cult: CultType.Arcane, color: '#6B7280' }),
    new Power({ id: 10, name: PowerType.Inferno, cult: CultType.Astral, color: '#F45C6E' }),
    new Power({ id: 11, name: PowerType.Mundane, cult: CultType.Terrene, color: '#FF9E6C' }),
    new Power({ id: 12, name: PowerType.Spirit, cult: CultType.Arcane, color: '#ECFFD2' }),
    new Power({ id: 13, name: PowerType.Aqueous, cult: CultType.Terrene, color: '#007CAF' }),
    new Power({ id: 14, name: PowerType.Geological, cult: CultType.Astral, color: '#E8C6C5' }),
    new Power({ id: 15, name: PowerType.Oblivion, cult: CultType.Arcane, color: '#918AEA' }),
    new Power({ id: 16, name: PowerType.Automaton, cult: CultType.Astral, color: '#ACA49E' }),
    new Power({ id: 17, name: PowerType.Numerary, cult: CultType.Astral, color: '#B5DECE' }),
    new Power({ id: 18, name: PowerType.Alchemy, cult: CultType.Astral, color: '#A3FEC2' }),
  ]

  constructor(data: any) {
    this.id = data?.id
    this.cult = Cult.getById(data?.cult)
    this.name = data?.name
    this.color = data?.color
  }

  static getByName(name: string): Power {
    return this.all.find(p => p.name == name);
  }

  static get(type: PowerType): Power {
    return this.all.find(p => p.name == type.toString());
  }

  static getById(id: number): Power {
    return this.all.find(p => p.id == id)
  }
}

export default class Gawd {
  public loaded: boolean = false
  public data: any = {}

  constructor(data = {}) {
    this.data = data
    this.data.id ||= 0
    this.data.hash ||= '0x00000000'
    this.data.name ||= 'Unknown'
    this.data.image ||= '/images/mystery-gawd.png'
    this.data.attributes ||= []
    this.data.assets ||= []

    // If assets exist, let's assume this is actually loaded
    if (this.data.assets.length > 0) {
      this.loaded = true
    }
  }

  async setPresignUrls() {
    if (this.loaded) {
      this.data.image = (await S3API.getPresignedUrl(this.data.image)) || '/images/mystery-gawd.png'
      this.data.animation_url = (await S3API.getPresignedUrl(this.data.animation_url)) || null

      for (let i = 0; i < this.data.assets.length; i++) {
        this.data.assets[i].url = (await S3API.getPresignedUrl(this.data.assets[i].url)) || '/images/mystery-gawd.png'
      }
    }
  }

  id() {
    return this.data.id
  }

  galleryUrl() {
    return `${process.env.BASE_URL}/gallery/${this.id()}`
  }

  openseaUrl() {
    return `https://opensea.io/assets/${process.env.CONTRACT_ADDRESS}/${this.id()}`
  }

  name() {
    return this.data.name
  }

  gif() {
    if (this.data && this.data.assets) {
      const gif = this.data.assets
        .sort((a1, a2) => a1.size.width - a2.size.width)
        .find((asset) => asset.contentType == 'image/gif')

      this.data.gif = gif ? gif.url.toGatewayUrl() : this.data.image
    } else {
      console.warn('Gawd.gifImage: No assets found/loaded')
    }
  }

  getImageBySize(size) {
    if (this.data && this.data.assets && this.loaded) {
      const image = this.data.assets.find((asset) => asset.size.width == size && asset.contentType == 'image/png')
      if (image) {
        return image.url.toGatewayUrl()
      }
    }

    // default to preview image
    return this.previewImage()
  }

  previewImage() {
    if (this.data && this.data.assets && this.loaded) {
      // Fetch the smallest image available
      const smallest_image = this.data.assets
        .sort((a1, a2) => a1.size.width - a2.size.width)
        .find((asset) => asset.size.width < 3000 && asset.contentType == 'image/png')

      if (smallest_image) {
        return smallest_image.url.toGatewayUrl()
      }
    }

    // No image found, default to default image
    return this.data.image
  }

  assets() {
    return this.data.assets.map((asset) => {
      asset.id = [asset.contentType, asset.spatial, asset.quiltType, asset.size.width, asset.size.height].join('_')
      asset.name = `${asset.contentType} (${asset.spatial})`
      asset.fileType = asset.contentType.split('/')[1].toUpperCase()
      asset.downloadable =
        asset.downloadable == true &&
        !((asset.size.width == 640 || asset.size.width == 1920) && asset.contentType == 'image/png')

      return asset
    })
      .sort((a, b) => {
        if (a.fileType < b.fileType && a.fileType != b.fileType)
          return 1
        else if (a.fileType > b.fileType && a.fileType != b.fileType)
          return -1

        return a.size.width < b.size.width ? 1 : -1
      })
  }

  cult(): string {
    for (let i = 0; i < Power.all.length; i++) {
      if (this.data.dominantPower == Power.all[i].name) {
        return Power.all[i].cult.name
      }
    }
  }

  public get dominantPower(): Power {
    return Power.getByName(this.data.dominantPower)
  }

  powers(): Object {
    const powerCounts = {}
    this.data?.attributes?.forEach((attribute) => {
      if (!attribute.power) {
        // console.warn(`attribute ${attribute.id} is missing power, skipping`)
        return
      }
      const key = attribute.power.toLowerCase()
      if (powerCounts[key]) {
        powerCounts[key] += 1
      } else {
        powerCounts[key] = 1
      }
    })
    return powerCounts
  }

  traits() {
    return (
      this.data?.attributes?.filter((trait) => {
        return !trait.trait_type.match(/^(Power|Set)/)
      }) || []
    )
  }

  // Gawchi stats...
  setTotalScore(number) {
    console.log(`gawd.id=${this.id()} setTotalScore=${number}`)
    this.data.totalScore = number
  }

  totalScore() {
    return this.data.totalScore
  }

  // DEPRECATED
  energyBarPercent() {
    return (400 - this.totalScore()) / 400 * 100
  }

  toJson() {
    return this.data
  }

  async updateAclToPublic() {
    if (this.loaded) {
      let updateAcls = []
      updateAcls.push(this.data.image)

      if (this.data.animation_url) {
        updateAcls.push(this.data.animation_url)
      }

      this.data.assets.forEach((asset) => {
        updateAcls.push(asset.url)
      })

      return await Promise.all(updateAcls.map((url) => S3API.updateAcl(url)))
    }
  }

  async load(): Promise<Gawd> {
    if (this.data.configUrl) {
      let tmp = await Gawd.loadByConfigUrl(this.data.id, this.data.configUrl)
      this.data = tmp.data
      this.loaded = tmp.loaded
      return tmp
    } else {
      console.warn(`No configUrl found for Gawd ${this.id()}`)
    }
  }

  /*
   * @param {int} id
   * @param {string} config_url
   * @return {Gawd}
   */
  static async loadByConfigUrl(id, config_url, retries = 0): Promise<Gawd> {
    const max_retries = 2

    config_url = config_url.toString().toGatewayUrl()

    console.debug(`Gawd.load${retries > 0 ? ` (Retry ${retries})` : ''} id=${id} =>`, config_url)

    try {
      const metadata = await S3API.fetch(config_url)

      if (metadata.status !== 200) {
        throw 'Error fetching configURL'
      }
      const data = await metadata.json()
      data.tokenURI = config_url
      const gawd = new Gawd(data)
      return gawd
    }
    catch (e) {
      if (retries < max_retries && config_url.match(/ipfs/)) {
        await delay(500)
        return await Gawd.loadByConfigUrl(id, config_url, retries + 1)
      }
      else {
        console.error(`Gawd.load: failed to fetch url=${config_url}`, e)
        captureException(e)
        return new Gawd({ id: id })
      }
    }
  }

  /**
   * @returns {Promise<Gawd>}
   */
  static async findById(id) {
    const index = id - 1
    let gawds = await GawdManifest.load()

    if (index in gawds) {
      return await this.loadByConfigUrl(id, gawds[index].configUrl)
    }
  }
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
